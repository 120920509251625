import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "reactstrap"
const NotFoundPage = () => (
	<Layout title="404: Not found">
		<SEO
			title="404: Not found"
			description="Simply put, it’s a network of professionals and business services that enables us to provide the best possible service to our candidates and clients."
		/>
		<div id="ts-page">
			<div className="page-headline bg-blue">
				<Container>
					<h1 className="bottom-underline text-white text-center">
						Site Maintenance!
					</h1>
				</Container>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
